import React from 'react';
import loadable from '@loadable/component';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import qs from 'qs';

const DesignComponent = loadable(() => import('../components/designerDetails'));

export default function Designer(location) {
  let route = false;
  if (location.location.search) {
    route = true;
  }

  // Query Params
  let queryParam = qs.parse(location.location.search, {
    ignoreQueryPrefix: true,
  });
  const proDesign = queryParam.shirt;

  return <DesignComponent proDesign={proDesign} route={route} />;
}
